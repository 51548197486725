// Tab Llamadas Expedientes // cabecera exped 50px mas contenedor // tabs 30px
//anchura // 1600px de anchura total // container (padding 12+12=24) 1576px //
principal-row (padding-right 8) 1568px // a cada marco sumar (margin-rigth 8px)
// marcos 2x776px=1552px (mas 16px de margin=1568px ) // marcos 3x513,3px (mas
24px de margin=1568px) // marcos 4x384px=1536px (mas 32px de margin=1568px) //
altura 679px //principal-row 691px (padding-top 12px) 679px //a cada marco sumar
(margin-bottom 8px) // a cada cabecera sumar 15px (23px cabecera mas marco) //
marcos 2x316,5px (mas 46px de cabecera y margen marco ) // marcos 3x203,3px (mas
69px de margin=1568px) // marcos 4x146,75px (mas 92px de margin=1568px)

<template>
<!-- style="width:1600px;background-color: #eee;" -->
  <div
    class=" comp_exp_llamada conflex pt-3 "
    style="background-color:#eee;justify-content:center"
    if="schema.ctrls.id.value !== ''">
  
    <div class="columna">      
      <!-- solicitante --------------------------------------------------------------->
      <div class="cab">SOLICITANTE</div>
      <v-sheet width="776px" height="180px" v-bind="$cfg.styles.marco">
        <!-- <div class="caja"> -->
        <div style="display:flex">        
          <compMultiple
            style="flex: 0 0 95%"
            :ctrl="schema.ctrls.callemp"           
            :schema="schema"            
            :disabled="!is_edit">
          </compMultiple>

          <!-- <v-btn v-show="estado== 'editar'" v-bind="$cfg.btn.busca" @click="get_poliza()">
            <v-icon large dark>{{ 'mdi-alpha-p' }}</v-icon>
          </v-btn> -->
          <v-btn v-show="estado=='editar' || estado=='nuevo'" v-bind="$cfg.btn.busca" @click="get_poliza()">
            <v-icon large color="green darken-2">{{ 'mdi-alpha-p' }}</v-icon>
          </v-btn>
        </div>

        <div style="display:flex">
          <v-text-field
            style="flex: 0 0 75%"
            class="name"
            v-bind="$input"
            v-model="schema.ctrls.callname.value"
            :label="schema.ctrls.callname.label"
            :disabled="!is_edit">
          </v-text-field>

          <v-text-field
            style="flex: 0 0 25%"
            v-bind="$input"
            v-model="schema.ctrls.calltlf.value"
            :label="schema.ctrls.calltlf.label"
            :disabled="!is_edit">
          </v-text-field>
        </div>

        <div style="display:flex">
          <v-text-field
            style="flex: 0 0 65%"
            v-bind="$input"
            v-model="schema.ctrls.callemail.value"
            :label="schema.ctrls.callemail.label"
            :disabled="!is_edit">
          </v-text-field>

          <v-text-field
            style="flex: 0 0 35%"
            v-bind="$input"
            v-model="schema.ctrls.callref1.value"
            :label="schema.ctrls.callref1.label"
            :disabled="!is_edit">
          </v-text-field>
        </div>

        <!-- </div> -->
      </v-sheet>

      <!-- Poliza --------------------------------------------------------------->
      <div class="cab">POLIZA</div>
      <v-sheet width="776px" height="490px" v-bind="$cfg.styles.marco">        
        <div style="display:flex">
          <v-text-field
            style="flex: 0 0 30%"
            v-bind="$input"
            v-model="schema.ctrls.poliza.value"
            :label="schema.ctrls.poliza.label"
            :disabled="!is_edit">
          </v-text-field>

          <v-text-field
            style="flex: 0 0 70%"
            v-bind="$input"
            v-model="schema.ctrls.fallename.value"
            :label="schema.ctrls.fallename.label"
            :disabled="!is_edit">
          </v-text-field>
        </div>

        <div style="display:flex">
          <div style="flex: 0 0 30%"></div>

          <uiText
            v-bind="$input"
            style="flex: 0 0 20%"
            v-model="schema.ctrls.falledni.value"
            :label="schema.ctrls.falledni.label"
            :disabled="!is_edit"
            format="cif"
            @input="schema.ctrls.falledni.value=$event">
          </uiText>

          <v-text-field
            style="flex: 0 0 10%"
            v-bind="$input"
            v-model="schema.ctrls.falleedad.value"
            :label="schema.ctrls.falleedad.label"
            :disabled="!is_edit"
          ></v-text-field>

          <!-- <v-text-field
            style="flex: 0 0 15%"
            v-bind="$input"
            v-model="schema.ctrls.fallesexo.value"
            :label="schema.ctrls.fallesexo.label"
            :disabled="!is_edit"
            @keydown="ctrlSex">>            
          </v-text-field> -->
          <v-select
            style="flex: 0 0 15%"
            v-bind="$select"
            v-model="schema.ctrls.fallesexo.value"
            :label="schema.ctrls.fallesexo.label"
            :items="itemsSexo"
            :disabled="!is_edit"
            item-value="name"
            item-text="name"
          ></v-select>

          <v-select
            style="flex: 0 0 25%"
            v-bind="$select"
            v-model="schema.ctrls.falleestadoc.value"
            :label="schema.ctrls.falleestadoc.label"
            :items="estadoCivil"
            :disabled="!is_edit"
            item-value="id"
            item-text="name"
          ></v-select>
        </div>

        <div style="display:flex">
          <v-text-field
            style="flex: 0 0 30%"
            v-bind="$input"
            v-model="schema.ctrls.clavep.value"
            :label="schema.ctrls.clavep.label"
            :disabled="!is_edit"
            @change="changeClave_produccion"
          ></v-text-field>

          <v-text-field
            style="flex: 0 0 65%"
            v-bind="$input"
            v-model="schema.ctrls.name_agencia.value"
            :label="schema.ctrls.name_agencia.label"
            :disabled=true>
          </v-text-field>       
          
          <!-- View Información de la Cuenta -->
          <div>
            <info_cta_view
              style="margin-left:5px"
              :padre="padre"
              :agencia=true
              :tipo_cta="Number(schema.ctrls.agencia.value)==1000? 0 : 4"
              :cta_id="schema.ctrls.agencia.value? schema.ctrls.agencia.value : 0"
              showFras="0">                        
            </info_cta_view>
          </div> 
        </div>

        <div style="display:flex">
          <compfecha style="flex: 1 0 25%" :schema="schema.ctrls.fhadhesion" :edicion="is_edit"></compfecha>
          <!-- <vlinput type="date" v-model="schema.ctrls.fhadhesion.value"> </vlinput> -->
          <v-checkbox
            style="flex: 1 0 10%"
            v-bind="$checkbox"
            v-model="schema.ctrls.encargo.value"
            :label="schema.ctrls.encargo.label"
            :disabled="!is_edit"
          ></v-checkbox>
          <v-checkbox
            style="flex: 1 0 10%"
            v-bind="$checkbox"
            v-model="schema.ctrls.poliza_pu.value"
            :label="schema.ctrls.poliza_pu.label"
            :disabled="!is_edit"
          ></v-checkbox>
          <v-checkbox
            style="flex: 1 0 10%"
            v-bind="$checkbox"
            v-model="schema.ctrls.rat.value"
            :label="schema.ctrls.rat.label"
            :disabled="!is_edit"
          ></v-checkbox>

          <v-checkbox
            style="flex: 1 0 10%"
            v-bind="$checkbox"
            v-model="schema.ctrls.ctepago.value"
            :label="schema.ctrls.ctepago.label"
            :disabled="!is_edit"
          ></v-checkbox>
          <v-text-field
            style="flex: 0 1 20%"
            v-bind="$input"
            v-model="schema.ctrls.me.value"
            :label="schema.ctrls.me.label"
            :disabled="!is_edit"
          ></v-text-field>
        </div>

        <div style="display:flex">
          <v-select
            style="flex: 0 0 50%"
            v-bind="$select"
            v-model="schema.ctrls.ctramit.value"
            :label="schema.ctrls.ctramit.label"
            :items="ST_ctramit"
            :disabled="!is_edit"
            item-value="id"
            item-text="label"
          ></v-select>
        </div>

        <div style="display:flex">
          <v-select
            style="flex: 0 0 50%"
            v-bind="$select"
            v-model="schema.ctrls.modalidad.value"
            :label="schema.ctrls.modalidad.label"
            :items="ST_modalidad"
            :disabled="!is_edit"
            item-value="id"
            item-text="name"
          ></v-select>
        </div>

        <div style="display:flex">
          <v-select
            style="flex: 0 0 50%"
            v-bind="$select"
            v-model="schema.ctrls.ramo.value"
            :label="schema.ctrls.ramo.label"
            :items="ST_ramo"
            :disabled="!is_edit"
            item-value="id"
            item-text="name"
          ></v-select>

          <div v-if ="schema.ctrls.ramo.value=='038'" style="padding-left:5px;">
            <span            
              style="color:red">
                RAMO 038: Comunicados como negocio directo. Facturas a AFS.
                Rellenar complemento en servicio.
            </span>
          </div>
        </div>

        <ctrlobs3
          :schemaComponente="schema.ctrls.callobs"
          :schema="schema"
          :record="record"
          :edicion="is_edit">
        </ctrlobs3>
        <!-- <ctrlobs :schema="schema.ctrls.callobs" :edicion="is_edit"></ctrlobs> -->
      </v-sheet>
    </div>

    <div class="columna">
      <!-- solicitante -->

      <div class="cab">DATOS FALLECIMIENTO</div>
      <v-sheet width="776px" height="223px" v-bind="$cfg.styles.marco">
        <div style="display:flex">
          <uiDate
            style="flex: 0 0 35%"
            v-model="schema.ctrls.fallefhhr.value"
            :label="schema.ctrls.fallefhhr.label"          
            type="datetime-local"
            :disabled="!is_edit">          
          </uiDate>
          <!--<compfecha 
            style="flex: 0 0 35%" 
            :schema="schema.ctrls.fallef
            hhr" 
            :edicion="is_edit">
          </compfecha>-->

           <!-- <vlinput type="datetime-local" v-model="schema.ctrls.fallefhhr.value"> </vlinput> -->
        </div>
        <div style="display:flex">
          <v-text-field
            style="flex: 0 0 50%"
            v-bind="$input"
            v-model="schema.ctrls.falleconsec.value"
            :label="schema.ctrls.falleconsec.label"
            :disabled="!is_edit"
          ></v-text-field>
          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.fallejudicial.value"
            :label="schema.ctrls.fallejudicial.label"
            :disabled="!is_edit"
          ></v-checkbox>
          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.fallecmed.value"
            :label="schema.ctrls.fallecmed.label"
            :disabled="!is_edit"
          ></v-checkbox>
        </div>

        <div style="display:flex">
          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.fallelugar.value"
            :label="schema.ctrls.fallelugar.label"
            :disabled="!is_edit"
          ></v-text-field>
          <ctrlfinder
            style="flex: 0 0 50%"
            finderName="atlas_F"
            :schema="schema.ctrls.fallelugarloc"
            :edicion="is_edit"
          ></ctrlfinder>
        </div>

        <div style="display:flex">
          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.falleen.value"
            :label="schema.ctrls.falleen.label"
            :disabled="!is_edit"
          ></v-text-field>

          <ctrlfinder style="flex: 0 0 50%" finderName="atlas_F" :schema="schema.ctrls.falleenloc" :edicion="is_edit"></ctrlfinder>
        </div>
        <div style="display:flex">
          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.falledest.value"
            :label="schema.ctrls.falledest.label"
            :disabled="!is_edit"
          ></v-text-field>

          <ctrlfinder
            style="flex: 0 0 50%"
            finderName="atlas_F"
            :schema="schema.ctrls.falledestloc"
            :edicion="is_edit"
          ></ctrlfinder>
        </div>
      </v-sheet>

      <!-- <div class="caja"> -->
      <div class="cab">CONTACTOS / FAMILIARES</div>
      <v-sheet width="776px" height="460px" v-bind="$cfg.styles.marco">
        <div style="display:flex">
          <v-text-field
            style="flex: 0 0 70%"
            v-bind="$input"
            v-model="schema.ctrls.fallecont1.value"
            :label="schema.ctrls.fallecont1.label"
            :disabled="!is_edit"
          ></v-text-field>
          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.fallecont1tlf1.value"
            :label="schema.ctrls.fallecont1tlf1.label"
            :disabled="!is_edit"
          ></v-text-field>
        </div>

        <div style="display:flex">
          <v-text-field
            style="flex: 0 0 70%"
            v-bind="$input"
            v-model="schema.ctrls.fallecont2.value"
            :label="schema.ctrls.fallecont2.label"
            :disabled="!is_edit"
          ></v-text-field>

          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.fallecont2tlf1.value"
            :label="schema.ctrls.fallecont2tlf1.label"
            :disabled="!is_edit"
          ></v-text-field>
        </div>

        <div style="display:flex">
          <v-text-field
            style="flex: 0 0 50%"
            v-bind="$input"
            v-model="schema.ctrls.soliname.value"
            :label="schema.ctrls.soliname.label"
            :disabled="!is_edit">
          </v-text-field>

          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.soliparen.value"
            :label="schema.ctrls.soliparen.label"
            :disabled="!is_edit">
          </v-text-field>      

           <vinput_cif
            v-bind="$input"
            v-model="schema.ctrls.solidni.value"
            :label="schema.ctrls.solidni.label"
            :disabled="!is_edit"
            @input="schema.ctrls.solidni.value=$event">
          </vinput_cif>   
          
        </div>

        <div style="display:flex">
          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.solitlf1.value"
            :label="schema.ctrls.solitlf1.label"
            :disabled="!is_edit">
          </v-text-field>

          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.solitlf2.value"
            :label="schema.ctrls.solitlf2.label"
            :disabled="!is_edit">
          </v-text-field>

          <v-text-field
            style="flex: 0 0 50%"
            v-bind="$input"
            v-model="schema.ctrls.soliemail.value"
            :label="schema.ctrls.soliemail.label"
            :disabled="!is_edit">
          </v-text-field>
        </div>

        <div style="display:flex">
          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.solidir.value"
            :label="schema.ctrls.solidir.label"
            :disabled="!is_edit">
          </v-text-field>

          <v-text-field
            style="flex: 0 0 20%"
            v-bind="$input"
            v-model="schema.ctrls.solicp.value"
            :label="schema.ctrls.solicp.label"
            :disabled="!is_edit">
          </v-text-field>
        </div>
        <div>
          <ctrlfinder finderName="atlas_F" :schema="schema.ctrls.soliloc" :edicion="is_edit"></ctrlfinder>
        </div>
        <div>
          <ctrlobs3 
            :schemaComponente="schema.ctrls.soliobs"
            :schema="schema"   
            :record="record"         
            :edicion="is_edit">
          </ctrlobs3>
        </div>
        <!-- <ctrlobs :schema="schema.ctrls.soliobs" :edicion="is_edit"></ctrlobs> -->
      </v-sheet>
    </div>
  </div>
</template>



<script>

  import { mixinFormato } from "@/mixins/mixinFormato";
  import plugs from "@/common/general_plugs";  
  const ctrlfinder = () => plugs.groute("ctrlfinder.vue", "comp");  
  const compfecha = () => plugs.groute("compfecha.vue", "comp");
  const ctrlobs3 = () => plugs.groute("ctrlobs3.vue", "comp");
  const compMultiple = () => plugs.groute("compMultiple.vue", "comp");
  const info_cta_view = () => plugs.groute("info_cta_view.vue", "comp");

  export default {
    components: { ctrlfinder, compfecha, compMultiple, ctrlobs3, info_cta_view },
    mixins:[mixinFormato],
    props: {
      padre: { type:String, default: '' },      
      componenteTipo: { type:String, default:'M' },      
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },

    data() {
      return {
        schema:null,
        ST_ctramit: [],
        ST_modalidad: [],
        ST_ramo: [],
        estadoCivil: [],
        itemsSexo: []     
      };
    },

    created() {
      this.ini_data();
    },

    methods: {
      ini_data() {
        console.log(" dev ********************** exp_M_llamada ini_data");

        // guardo del store en variables locales
        this.schema = this.$store.state[this.padre].schema;  

        this.ST_ctramit = this.$store.state.datos_iniciales.ct;
        this.ST_modalidad = this.$store.state.datos_iniciales.modalidades;
        this.ST_ramo = this.$store.state.datos_iniciales.ramos;
        this.estadoCivil = [
          { id: "0", name: "Seleccione Estado" },
          { id: "1", name: "Soltero/a" },
          { id: "2", name: "Casado/a" },
          { id: "3", name: "Divorciado/a" },
          { id: "4", name: "Viudo/a" }
        ];

        this.itemsSexo= [
          { id:'0', name:'H'},
          { id:'1', name:'M'}
        ]

        //       
        console.log("dev ********************** exp_M_llamada ini_data fin");
      },


      //
      get_poliza() {
        console.log("DEV "+this.Entorno.api+" ********************* get_poliza", this.schema.ctrls.poliza_MAPFRE);

        if (this.schema.ctrls.callemp.value== '') return;
        if (this.schema.ctrls.callemp.value== 83) {
          // Póliza de MAPFRE
          this.$root.$common_finder.open('polizasMapfre_F', this.schema.ctrls.poliza_Finder, 'FC', this.extraid_polizaMapfre, this.padre);
          return;
        }
          
        // Póliza de Kutxabank          
        this.$root.$common_finder.open('polizas_F', this.schema.ctrls.poliza_Finder, 'FC', this.extraid_Poliza);        
      },


      //
      extraid_Poliza(r) {        
        if (!r) return;
       
        if (r.aviso=='X') {
          this.$root.$alert.open('<u>ATENCIÓN</u><br><br>POLIZA MARCADA CON AVISO<br>*Compañia: <b>' + r.cia_name + "</b><br>", 'error');
          //
        }

        // Datos importados de la tabla de polizas poliza        
        this.schema.ctrls.callemp_tp.value= "2";
        this.schema.ctrls.callemp.value= r.cia_id;
       
        this.schema.ctrls.poliza.value= r.poliza;
        this.schema.ctrls.fallename.value= r.name;
        this.schema.ctrls.falledni.value= r.dni;
        this.schema.ctrls.fallesexo.value= r.sex;        
        //this.schema.ctrls.falleedad.value= this.calc_edad({format:'fh'}, r.fhnac);
        this.schema.ctrls.falleedad.value= r.edad;
        this.schema.ctrls.fhadhesion.value= r.fh;        
        this.schema.ctrls.poliza_pu.value= r.puni;
        this.schema.ctrls.inhumacion.value= "1";
        this.schema.ctrls.incineracion.value= "1";
        this.schema.ctrls.crionizacion.value= "1";
        //this.schema.ctrls.p_asisp.value= "1";
        this.schema.ctrls.servnicho.value= r.nicho;
        this.schema.ctrls.servpm.value= r.cober;

        //observaciones introducido desde polizas
        var a='**Datos extraidos POLIZAS ASEGURADOS.' +
          (r.puni==1? '\nPRIMA UNICA.' : '' );
        this.schema.ctrls.callobs.comp.valueaux= a + this.schema.ctrls.callobs.comp.valueaux;        
      },


      //
      extraid_polizaMapfre(r) {

        console.log('**extraid_polizaMF', r);

        if (!r) return;
               
        // Datos importados de la tabla de polizas poliza        
        this.schema.ctrls.callemp_tp.value= "2";
        this.schema.ctrls.callemp.value= 83;

        this.schema.ctrls.poliza.value= r.poliza;
        this.schema.ctrls.ccomer.value= r.ccomer;
        this.schema.ctrls.poliza_loc.value= r.poliza_loc;
        this.schema.ctrls.poliza_cpo.value= r.poliza_cpo;
        this.schema.ctrls.poliza_ase.value= r.poliza_ase;
       
        this.schema.ctrls.fhadhesion.value= r.fhadhesion;
        this.schema.ctrls.ctramit.value= r.ctramit;
        this.schema.ctrls.fallename.value= r.fallename;
        //this.schema.ctrls.falledni.value= r.falledni;
        this.schema.ctrls.fallesexo.value= r.fallesexo;
        this.schema.ctrls.rat.value= String(r.rat);
        this.schema.ctrls.fallenacim.value= r.fallenacim;
        this.schema.ctrls.falleedad.value= r.falleedad;
        this.schema.ctrls.clavep.value= r.clavep;
        this.schema.ctrls.agencia.value= r.agencia;
        this.schema.ctrls.directo.value= r.directo;
        this.schema.ctrls.zona_agencia.value= r.zona_agencia;
        this.schema.ctrls.ramo.value= r.ramo;
        this.schema.ctrls.modalidad.value= r.modalidad;

        //importes
        this.schema.ctrls.servpm.value= r.servpm;
        this.schema.ctrls.servnicho.value= r.servnicho;
        this.schema.ctrls.servlapida.value= r.servlapida;
        this.schema.ctrls.servtana.value= r.servtana;
        this.schema.ctrls.servcomplemento.value= r.servcomplemento;

        //checkbox -> convertir 0,1 a string
        this.schema.ctrls.local.value= String(r.local);
        this.schema.ctrls.provincial.value= String(r.provincial);
        this.schema.ctrls.nacional.value= String(r.nacional);
        this.schema.ctrls.internacional.value= String(r.internacional);
        this.schema.ctrls.apd.value= String(r.apd);
        this.schema.ctrls.gestor.value= String(r.gestor);
        this.schema.ctrls.asisp.value= String(r.asisp);
        this.schema.ctrls.incineracion.value= String(r.incineracion);
        this.schema.ctrls.inhumacion.value= String(r.inhumacion);
        this.schema.ctrls.crionizacion.value= String(r.crionizacion);

        if (r.ramo!= '030') {
            this.schema.ctrls.servasegdec.value= "1";				 
        }
         
        /*Flink.F.llamada.verrecibos.visible=false;
          if (item.RECIBO_STD.substring(0,7)!="COBRADO"){
					Flink.F.llamada.verrecibos.visible=true
        } */			
        
        // generamos documento de Póliza
        this.consulta_poliza();
      },


      // genero documento de Póliza
      consulta_poliza() {
        var self= this;

        this.$root.$alert.open("¿Generamos Documento CONSULTA PÓLIZA?", "confirm")
        .then(async function(result) {          
          if (!result) return;
          
          // obtengo records
          var args= { tipo:'fnc', accion: 'expediente', fn_args: { accion:'solicitud_servicio', id: self.$store.state[self.padre].record.id }};          
          var apiResult= await self.$store.dispatch("ajaxRequest", { args: args });
          
          if (apiResult.sql.error) self.$root.$alert.open(apiResult.sql.msg, 'error');
        });
      },


      // filtro campo sexo solo por letras H yM
      ctrlSex(evt) { 
        console.log(evt.key);       
        var permittedChars= ["H", "M"];
        var specialKeys= ["ArrowLeft", "ArrowRight", "Backspace", "Delete"]
        
        if (specialKeys.indexOf(evt.key)>= 0) return;
        if (permittedChars.indexOf(evt.key)< 0 || this.schema.ctrls.fallesexo.value.length> 0) event.preventDefault();
        
      },


      // cambio clave producción
      changeClave_produccion() {
        // si NO es Mapfre, salgo
        if (this.schema.ctrls.callemp.value!= 83) return;

        const self = this;
        this.$root.$alert
          .open("Si modifica la CLAVE DE PRODUCCION<br>deberá introducir de nuevo los APDS", "confirm")
          .then(async function(r) { 
            if (r) return self.modificar_agencia();            
            self.schema.ctrls.clavep.value= self.record.clavep;
        });

      },


      //
      async modificar_agencia() {
     
        // 
        let args = { tipo: 'fnc', accion: 'expediente', fn_args: { accion: "claveProdMF", clave: this.schema.ctrls.clavep.value }};         
        console.log('args: ', args);
        let apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
        console.log('apiResult: ', apiResult);
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        } 
        
        // actualizo datos de controles con los recibidos       
        this.schema.ctrls.agencia.value= apiResult.r[0].agencia;
        this.schema.ctrls.name_agencia.value= apiResult.r[0].name_agencia;
       

        // eliminio APDS
        this.schema.ctrls.apd1.value= "0";
        this.schema.ctrls.apd1.comp.valueaux= "";

        this.schema.ctrls.apd2.value= "0";
        this.schema.ctrls.apd2.comp.valueaux= "";
      },
    
    },

    

    computed:{
      estado: function() { return this.$store.state[this.padre].estado; },
      record: function() { return this.$store.state[this.padre].record; },

      // devuelvo si estamos en modo edición en el Mto
      is_edit() { 
        return this.$store.state[this.padre].estado === "editar" || this.$store.state[this.padre].estado === "nuevo"? true : false;
      },
    }
  };
</script>
